/**
*   header.scss
*   @description: Estilos del Header
*/

header {
    padding: $paddingNormal 0;
    background-color: $black;
    img {
        height: 60px;
    }
    .nav-item {
        padding: 0 $paddingNormal;
        border-bottom: 2px solid transparent;
        transition: border-bottom 300ms ease-in-out;
        &:hover { border-bottom: 2px solid $white; }
        i { padding-right: 4px; }
    }

}