/**
*   card-tables.scss
*   @description: Estilos de tarjetas de tablas
*/

.card-table {
    max-height: 340px;
    border-radius: 4px;
    padding: $paddingMedium;
    background-color: $white;
    box-shadow: 1px 0 20px rgba(0,0,0,.05);
    ::-webkit-scrollbar { width: 4px; }
    ::-webkit-scrollbar-thumb { background-color: #cccccc; }

    th {
        padding: $paddingNormal 0;
        border-bottom: 2px solid rgba($secondary, 0.4);
    }

    td {
        padding-top: 8px;
    }

    tr {
        transition: 300ms ease-in-out;
        &:hover { background-color: rgba(#f2f2f2, 0.6);  }
    }

}

.card-table-1 {
    width: 60%;
    margin-right: $marginLarge;
    td {
        padding: 8px 0;
        border-bottom: 2px solid $light;
    }
}

.card-table-2 {
    width: 40%;
    align-self: flex-start;
    td { border-bottom: 2px solid $light; }
}

.card-table-guests {
    height: auto;
    max-height: initial;
}