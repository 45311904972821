/**
*   modals.scss
*   @description: Estilos para modales
*/

.modal {
    top: 50%;
    left: 50%; 
    width: 50%;
    outline: none;
    max-height: 90%;
    transition: max-height 1.5s ease-in-out;
    overflow-y: scroll;
    position: absolute;
    border-radius: 4px;
    padding: $paddingMedium;
    background-color: $white;
    transform: translate(-50%, -50%);

    .btn-container {
        button {
            width: 45%;
        }
    }

}

.overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    transition: height 1500ms ease-in-out;
    background-color: rgba($black, 0.5);
    ::-webkit-scrollbar { width: 6px; }
    ::-webkit-scrollbar-thumb { background-color: #cccccc; }
}

.modal-houses {
    p {
        min-width: 20%;
    }
    .inquilino-name {
        width: 65%;
    }
    .inquilino-btns {
        width: 35%;
    }
}
