.sidebar {
    height: 100%;
    width: 200px;
    position: sticky;
    height: 100vh;
    z-index: 1;
    top: 0;
    color: $white;
    left: 0;
    background-color: $black;
    overflow-x: hidden;
    transition: 300ms;

    .logo {
        width: 120px;
    }

    .active, .accordion:hover {
        background-color: $primary;
    }

    .nav-item {
        padding-left: $paddingMedium;
        padding: 0px 32px;
        transition: 300ms;
        width: 100%;

        &:hover {
            background-color: #6c757d8a;
        }

    }
}

#content {
    transition: margin-left 300ms;
    width: 100%;
}

.maxHeight {
    max-height: 136px !important;
}

/*LEFT SIDEBAR*/
.rightsidebar {
	position: fixed;
	right: 0px;
	background-color: $black;
	height: 100%;
	width: 0px;
	overflow-x: hidden;
    transition: 300ms;
	z-index: 1;
	padding-top: 100px;

	&.open {
		width: 220px;
		padding-left: 10px;
	}
}
.nav-exit:hover{
    color: $primary;
}

/*Cambio de contraseña*/

.change-password {
    .box {
        width: 85%;

        .labelpass {
            padding-right: 12px;
        }
    }
}