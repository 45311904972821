/**
*   card-incident.scss
*   @description: Estilos de tarjetas de incidencias
*/

.card-owners {

    height: auto;
    max-height: initial;

    .name { width: 20%; }

    .description { width: 50%; }

    .description-detail { font-size: $fontText; }

    .vivienda { width: 10%; }

    .fecha { width: 20%; }

    td { border-bottom: 2px solid $light; }    

    .proveedor { width: 15%; }

    .services { width: 15%; }

    .phone { width: 15%; }

    .city { width: 15%; }

}