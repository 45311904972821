/**
*   card-incidents.scss
*   @description: Estilos de tarjetas de incidencias en la página de incidencias
*/

.card-incidents {

    height: auto;
    max-height: initial;

    .type { 
        padding-right: $paddingMedium;
    }

    .name { width: 20%; }

    .date {
        padding: 0 $paddingMedium;
    }

    .description { 
        width: 45%;
        padding: 0 $paddingMedium;
    }

    .resolution {
        padding: 0 $paddingMedium;
    }

    .more {
        padding: 0 $paddingNormal;
    }    

    td {
        border-bottom: 2px solid $light;
    }

    .tr {
        height: 120px;
    }    

}